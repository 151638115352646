import { loadingController } from '@ionic/vue'

export async function presentLoading(timeout = 5) {
  const loading = await loadingController
    .create({
      cssClass: 'my-custom-class',
      message: 'Please wait...',
      duration: timeout,
    })

  await loading.present()

  setTimeout(() => {
    loading.dismiss()
  }, timeout)
}

export async function presentLoadingWithOptions(timeout = 5) {
  const loading = await loadingController
    .create({
      spinner: null,
      duration: timeout,
      message: 'Click the backdrop to dismiss early...',
      translucent: true,
      cssClass: 'custom-class custom-loading',
      backdropDismiss: true,
    })

  await loading.present()

  setTimeout(() => {
    loading.dismiss()
  }, timeout)
}

export async function awaitLoading<Type>(dataPromise: Promise<Type>): Promise<Type> {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve) => {
    const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Bitte warten...',
      })
    await loading.present()
    const data = await dataPromise
    loading.dismiss()
    resolve(data)
  })
}

import { acceptHMRUpdate, defineStore } from 'pinia'
import type { SwaggerResponse } from '~/api-services/config'
import { getUsersId, patchUsersCurrent, postAuthentication_token, putChange_password } from '~/api-services/services'
import type { UserJsonld } from '~/api-services/types'

export const useUser = defineStore('user', () => {
  const loginToken = useSessionStorage('login_token', '')
  const refreshToken = useLocalStorage('refresh_token', '')

  const loginCheckLifetime = 1000 * 60 * 10
  const lastLoginCheck = ref<null | number>(null)

  const _user = ref<UserJsonld | null>(null)

  let userPromise: null | Promise<SwaggerResponse<UserJsonld>> = null

  const getUser = async () => {
    if (!userPromise)
      userPromise = getUsersId('current')

    if (!_user.value) {
      try {
        _user.value = await userPromise
      }
      catch (error) { }
    }

    return _user.value
  }

  async function reloadUser() {
    userPromise = null
    _user.value = null
    return await getUser()
  }

  const user = computed(() => {
    if (_user.value === null)
      getUser()

    return _user.value
  })

  const changePassword = async (oldPassword: string, newPassword: string) => {
    try {
      await putChange_password({
        oldPassword,
        newPassword,
      })
      return true
    }
    catch (error) { }
    return false
  }

  const changeDefaultAddress = async (defaultAddress: number | undefined) => {
    try {
      await patchUsersCurrent({
        defaultAddress,
      })
      await reloadUser()
      return true
    }
    catch (error) { }
    return false
  }

  const login = async (username: string, password: string) => {
    try {
      const tokens = await postAuthentication_token({
        username,
        password,
      })
      if (tokens.refresh_token && tokens.token) {
        loginToken.value = tokens.token
        refreshToken.value = tokens.refresh_token
        return true
      }
    }
    catch (error) { }
    return false
  }

  const isLoggedIn = computed(() => {
    if (refreshToken.value && lastLoginCheck.value !== null && (lastLoginCheck.value + loginCheckLifetime) > Date.now())
      return true
    return false
  })

  const checkLogin = async () => {
    if (!localStorage.getItem('refresh_token'))
      return false
    try {
      const user = await getUser()
      if (user) {
        lastLoginCheck.value = Date.now()

        return true
      }
    }
    catch (error) {
      console.error(error)
    }
    return false
  }

  const hasUser = asyncComputed(() => isLoggedIn.value || checkLogin())

  const logout = () => {
    sessionStorage.removeItem('login_token')
    localStorage.removeItem('refresh_token')
    window.location.href = '/login'
  }

  function setToken(token: string) {
    loginToken.value = token
  }

  function setRefreshToken(token: string) {
    refreshToken.value = token
  }
  return { getUser, user, changePassword, checkLogin, isLoggedIn, loginToken, refreshToken, login, logout, setToken, setRefreshToken, hasUser, changeDefaultAddress }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUser, import.meta.hot))
